import React from "react";
import TextInputFormDemo from "./form-demos/TextInputFormDemo";
import DropdownFormDemo from "./form-demos/DropdownFormDemo";
import CustomValidationDemo from "./form-demos/CustomValidationDemo";

const BasicFormsDemo = () => {
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Basic Forms</h3>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12 my-2">
                  <h5>Input Text</h5>
                  <p>
                    Here’s an example of a text input form using react-hook-form
                    that includes validation, default values, real-time
                    feedback, and error handling.
                  </p>
                  <p>Validations for username</p>
                  <ul className="list">
                    <li>All fields are required</li>
                    <li>Atleast 3 charaters and atmost 20 charaters</li>
                    <li>Should only contain alphabets</li>
                  </ul>
                  <p>Validations for phone number</p>
                  <ul className="list">
                    <li>All fields are required</li>
                    <li>Must be exactly 10 digits</li>
                    <li>Contain only numbers</li>
                  </ul>
                  <TextInputFormDemo />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12 my-2">
                  <h5>Input Dropdown</h5>
                  <p>
                    This section outlines the validation logic implemented in
                    the dropdown.
                  </p>
                  <p>Validations for dropdown</p>
                  <ul className="list">
                    <li>All fields are required</li>
                  </ul>
                  <DropdownFormDemo />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12 my-2">
                  <h5>Custom Validation</h5>
                  <p>
                    This section outlines the custom validation logic
                    implemented in the form to ensure that the start date
                    entered by the user is less than or equal to the end date.
                  </p>
                  <p>Validations for dropdown</p>
                  <ul className="list">
                    <li>All fields are required</li>
                    <li>
                      End date should be greater than or equal to start date
                    </li>
                  </ul>
                  <CustomValidationDemo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicFormsDemo;
