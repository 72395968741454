import React, { useContext, useState } from 'react'
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { StateContext } from '../../Context/StateContext';

const options = [
  { value: 'first_name', label: 'First Name' },
  { value: 'last_name', label: 'Last Name' },
  { value: 'email', label: 'Email' },
  { value: 'date_of_birth', label: 'Date of Birth' },
  { value: 'favorite_color', label: 'favorite Color' },
];

const ColumnOrdering = () => {
  const navigate = useNavigate()
  const location = useLocation()
  console.log(location)

  const { setColumnOrder } = useContext(StateContext)

  const [selected, setSelected] = useState([]);
  console.log(selected)

  const applyChanges = () => {
    setColumnOrder(selected)
    navigate("/basic-table")
  }
  return (
    <>
      <DualListBox
        options={options}
        selected={selected}
        preserveSelectOrder 
        onChange={(newValue) => setSelected(newValue)}
      />
      <a className='btn btn-primary mt-2' onClick={() => applyChanges()}>Apply Changes</a>
    </>
  )
}

export default ColumnOrdering