import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import Filter from "./Filter";

const Table = (props) => {
  const privileges = ["edit_department", "edit-product", "edit-student"]
  // for filters
  const [selectedFilters, setSelectedFilters] = useState({});
  console.log(selectedFilters)

  // for sorting
  const [sortingDate, setSortingDate] = useState({ key: null, direction: null });
  console.log(sortingDate)

  const handleEntries = (event) => {
    console.log(event);
    props.setEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    props.setSearchBox(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      props.getFunctionData(1, props.searchBox, selectedFilters,sortingDate);
    } else {
      props.getFunctionData(1, "", selectedFilters,sortingDate);
      props.setSearchBox("");
    }
  };

  // for nested key in database storage.
  const getNestedValue = (obj, key) => {
    return key.split('.').reduce((o, k) => (o && o[k] !== 'undefined') ? o[k] : undefined, obj);
  };

  // Sorting Function for the loop of null -> asce -> dece -> (Need to verify once)
  const handleSort = (columnKey) => {
    if (sortingDate.key === columnKey) {
      // Cycle through null -> asc -> desc -> asc...
      if (sortingDate.direction === null) {
        setSortingDate({ key: columnKey, direction: 'asc' });
        props.getFunctionData(1, props.searchBox, selectedFilters,sortingDate);
      } else if (sortingDate.direction === 'asc') {
        setSortingDate({ key: columnKey, direction: 'desc' });
        props.getFunctionData(1, props.searchBox, selectedFilters,sortingDate);
      } else if (sortingDate.direction === 'desc') {
        setSortingDate({ key: columnKey, direction: 'asc' });
        props.getFunctionData(1, props.searchBox, selectedFilters,sortingDate);
      }
    } else {
      // For a new column, always start with 'asc'
      setSortingDate({ key: columnKey, direction: 'asc' });
      props.getFunctionData(1, props.searchBox, selectedFilters,sortingDate);
    }
  };

  useEffect(() => {
    props.getFunctionData(1, props.searchBox, selectedFilters, sortingDate);
  }, [props.entries, selectedFilters]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">{props.title}</h3>
            {props.isLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          {props.create_button === "true" && <div className="nk-block-head-content">
            <NavLink
              to={props.create_button_path} className="toggle btn btn-icon btn-primary d-md-none"
              id="button-button-addagency">
              <em className="icon ni ni-plus"></em>
            </NavLink>

            <NavLink
              to={props.create_button_path} className="toggle btn btn-primary d-none d-md-inline-flex"
              id="button-button-addagency">
              <em className="icon ni ni-plus"></em>
              <span>{props.create_button_name}</span>
            </NavLink>
          </div>}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="row">
                <div className="col-lg-11 col-md-6 col-sm-12">
                  <div className="form-control-wrap">
                    <form>
                      <div className="input-group">
                        <input
                          type="text"
                          value={props.searchBox}
                          className="form-control"
                          placeholder={props.seachPlaceholder}
                          onChange={onChangeHandler}
                          id="text-form-input-search"
                        />
                        <div className="input-group-append">
                          <button
                            type="submit"
                            className="btn btn-outline-primary btn-dim"
                            id="button-button-search"
                            onClick={(e) => onSearchHandler("search", e)}
                          >
                            Search
                          </button>
                          <button
                            className="btn btn-outline-success btn-dim"
                            id="button-button-view"
                            onClick={(e) => onSearchHandler("view", e)}
                          >
                            Clear Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-1 col-md-6 col-sm-12">
                  <button
                    type="button"
                    className="btn btn-white btn-outline-light"
                    data-bs-toggle="modal"
                    data-bs-target="#modalForm"
                  >
                    <em className="icon ni ni-filter-alt"></em>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      {props.headers.map((header, index) => {
                        return (
                          <div className="nk-tb-col" key={index}>
                            <span className="d-flex align-center">
                              {header.header_name}
                              <div onClick={() => handleSort(header.sort_key)}>
                                {sortingDate.key === header.sort_key ? (sortingDate.direction === 'asc' ? "🔼" : sortingDate.direction === 'desc' ? "🔽" : "↕️") : "↕️"}</div>
                              {/* {header.sorting.is_sorting && (
                                <div className="card-tools me-n1 ms-1">
                                  <ul className="btn-toolbar gx-1">
                                    <li>
                                      <div className="dropdown">
                                        <a
                                          href="javascript:void(0)"
                                          className="btn btn-trigger btn-icon dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-filter-alt"></em>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm">
                                          <div className="dropdown-head">
                                            <a
                                              href="javascript:void(0)"
                                              className="sub-title dropdown-title"
                                              onClick={() =>
                                                handleSorting(
                                                  header.sorting.asce_by
                                                )
                                              }
                                            >
                                              {header.sorting.asce_name}
                                            </a>
                                          </div>
                                          <div className="dropdown-head">
                                            <a
                                              href="javascript:void(0)"
                                              className="sub-title dropdown-title"
                                              onClick={() =>
                                                handleSorting(
                                                  header.sorting.desc_by
                                                )
                                              }
                                            >
                                              {header.sorting.desc_name}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              )} */}
                            </span>
                          </div>
                        );
                      })}
                    </div>

                    {props.rawDetails.length > 0 ? (
                      props.rawDetails.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          {props.headers.map((header, index) => {
                            const value = getNestedValue(post, header.display_keyname)
                            return (
                              <>
                                {header.type === "text" && (
                                  <div
                                    className="nk-tb-col"
                                    key={header.display_keyname}
                                  >
                                    <span className="tb-sub">
                                      {value || "-"}
                                    </span>
                                  </div>
                                )}
                                {header.type === "Navlink" && (
                                  <div
                                    className="nk-tb-col"
                                    key={header.display_keyname}
                                  >
                                    <span className="tb-sub">
                                      {header.privilege &&
                                        privileges.includes(header.privilege) ? (
                                        <NavLink
                                          to={header.path}
                                          id={`button-${header.id}`}
                                          state={{ editpost: post }}
                                        >
                                          <span>
                                            {value ||
                                              "-"}
                                          </span>
                                        </NavLink>
                                      ) : (
                                        <>
                                          {value || "-"}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                )}
                                {header.type === "button" && (
                                  <div
                                    className="nk-tb-col"
                                    key={header.display_keyname}
                                  >
                                    {header.privilege &&
                                      privileges.includes(header.privilege) ? (
                                      <NavLink
                                        to={header.path}
                                        state={{ editpost: post }}
                                        className="btn btn-sm btn-primary"
                                        id={`button-${header.id}`}
                                      >
                                        <em
                                          className={`icon ni ${header.button_icon}`}
                                        ></em>
                                      </NavLink>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      ))
                    ) : props.isData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading.....</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={props.pageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={props.pageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      props.handleClick(data, props.searchBox)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {props.count === null ? 0 : props.count}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select"
                        data-dropdown="xs center"
                        value={props.entries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Filter filters={props.filters}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters} />
    </>
  );
};

export default Table;
