import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const Login = (props) => {
  const env_url = process.env.REACT_APP_API_URL
  // React hook form
  const { register, handleSubmit, formState: { errors } } = useForm();

  // Password show/hide state to handle
  const [showPassword, setShowPassword] = useState(false);
  
  const [submitLoading,setSubmitLoading] = useState(false)

  const onSubmit = (data) => {
    // Note : Either it is email, password or username, password completely depends on 
    // clients requirement (Do changes as needful)

    // Note : If the payload is in "form data" use below code to send data

    // let user = new FormData();
    // user.append("email", data.email);
    // user.append("password", data.password);

    // Note : If the payload is in "application/json" use below code to send data
    let user = JSON.stringify({
      "username": data.username,
      "password": data.password
    });

    const url = env_url + "/api/login/";
    console.log(url);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: url,
      headers: { 
        'Content-Type': 'application/json'
      },
      // If the payload is in "form data" remove the 'Content-Type': 'application/json' from headers
      data : user
    };

    setSubmitLoading(true)
    axios.request(config).then((response) => {
      console.log(response);
      const user_data = response.data
      if(response.status == 200) {
        // props.loginHandler is called from APP.JS file
        props.loginHandler(user_data)
        setSubmitLoading(false)
      }
    }).catch((error) => {
      setSubmitLoading(false)
      console.log(error.response);
      // Here sweetalert is use. (You can use any UI to display message)
      // Depends on Client Requirements too
      
      // In Error, You can also pass "response.message" in text field 
      // for better understanding
      Swal.fire({
        title: "Login Failed!",
        text: "There was an error logging into your account. Please try again.",
        icon: "error"
      });
    });
  };

  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap nk-wrap-nosidebar">
            {/* <!-- content @s --> */}
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <a href="html/index.html" className="logo-link">
                    <img className="logo-light logo-img logo-img-lg" src="./images/logo.png" srcset="./images/logo2x.png 2x" alt="logo" />
                    <img className="logo-dark logo-img logo-img-lg" src="./images/logo-dark.png" srcset="./images/logo-dark2x.png 2x" alt="logo-dark" />
                  </a>
                </div>
                <div className="card">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title">Login</h4>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row" style={{ gap: "2rem" }}>
                        <div className="col-sm-12">
                          <label className="visually-hidden" htmlFor="email">
                            Email
                          </label>
                          <div className="input-group">
                            <div className="input-group-text">
                              <em className="icon ni ni-mail fs-5"></em>
                            </div>
                            <input type="text" className="form-control form-control-lg"
                              id="text-forms-username" placeholder="Enter your username"
                              {...register("username", {
                                required: "Username is required",
                                validate: (value) =>
                                  value === value.toLowerCase() ||
                                  "Username should only contain lowercase letters",
                              })}
                            />
                          </div>
                          {errors.username && (<span className="fs-8 text-danger">{errors?.username?.message}</span>)}
                        </div>
                        <div className="col-sm-12">
                          <div className="form-control-wrap">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <em className="icon ni ni-lock-alt fs-5"></em>
                                </span>
                              </div>
                              <input type={`${showPassword === true ? "text" : "password"}`}
                                className={`${showPassword === true ? "form-control form-control-lg valid is-shown" : "form-control form-control-lg"}`}
                                id="password-forms-password" placeholder="Enter your password"
                                {...register("password", { required: true })}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text p-0" style={{ zIndex: "100" }}>
                                  <a href="javascript:void(0)" onClick={() => setShowPassword(!showPassword)}
                                    className={`${showPassword === true
                                      ? "form-icon form-icon-right passcode-switch lg is-shown"
                                      : "form-icon form-icon-right passcode-switch lg"
                                      }`} data-target="password">
                                    <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                    <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                  </a>
                                </span>
                              </div>
                            </div>
                            {errors.password && (<span className="text-danger">Password is required</span>)}
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            {submitLoading === true ? (
                              <button
                                id="button-loading"
                                className="btn text-dark">
                                Loading...
                              </button>
                            ) : (
                            <button
                              type="submit"
                              className="btn btn-lg btn-info btn-block fs-4 fw-normal"
                              id="submit-button-login"
                              atl="signin">
                              Login
                            </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- wrap @e --> */}
          </div>
          {/* <!-- content @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
      {/* <!-- app-root @e --> */}
    </>
  );
};

export default Login;
