import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import mock_data from "../Data/MOCK_DATA.json"

export const MasterContext = createContext(null);

function MasterContextProvider({ children }) {
  const env_url = process.env.REACT_APP_API_URL

  const [favoriteColor,setFavoriteColor]=useState(mock_data.requisition_acceptance)
  const [companyName,setCompanyName]=useState(mock_data.department_id)
  const [collegeName,setCollegeName]=useState([])

  const getMasterDetails = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: env_url + "/api/masters/",
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios(config);
      let response = res.data;
      console.log(res.data)
      setFavoriteColor(response.filter((api) => api?.category?.category_value === "favorite_color" ))
      setCompanyName(response.filter((api) => api?.category?.category_value === "company_name" ))
      setCollegeName(response.filter((api) => api?.category?.category_value === "college_name" ))
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMasterDetails()
  },[])

  return (
    <div>
      <MasterContext.Provider
        value={{favoriteColor,companyName,collegeName
        }}
      >
        {children}
      </MasterContext.Provider>
    </div>
  );
}

export default MasterContextProvider;
