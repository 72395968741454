import React, { useState } from "react";
import Chart from "react-apexcharts";

const LineChart = () => {
  const [options, setOptions] = useState({
    chart: {
      id: "basic-line",
      zoom: { enabled: true },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
    stroke: {
      curve: "smooth", // Makes the line smooth
      width: 2,
    },
    markers: {
      size: 5, // Data point markers
    },
    tooltip: {
      enabled: true, // Tooltips enabled
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Sales",
      data: [30, 40, 35, 50, 49, 60, 70],
    },
    {
      name: "Revenue",
      data: [20, 30, 25, 40, 45, 50, 60],
    },
  ]);

  return (
    <div>
      <Chart options={options} series={series} type="line" height="400" />
    </div>
  );
};

export default LineChart;
