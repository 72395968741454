import React from "react";
import Swal from "sweetalert2";

const ReactSweetAlert2Demo = () => {
  const performBasicSweetalert = () => {
    Swal.fire("Basic sweet alert!");
  };
  const performWithTextUnder = () => {
    Swal.fire({
      title: "The Internet?",
      text: "That thing is still around?",
      icon: "question",
    });
  };
  const performWithSuccess = () => {
    Swal.fire({
      title: "Good job!",
      text: "You clicked the button!",
      icon: "success",
    });
  };
  const performWithError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
  };
  const performWithConfirm = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };
  const performWithThreeActions = () => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const performWithCancle = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Your imaginary file is safe :)",
            icon: "error",
          });
        }
      });
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">React Sweetalert2</h3>
            <p className="lead">For more details, visit this <a className="text-info" href="https://sweetalert2.github.io/" target="_blank">React Sweetalert</a></p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-primary mt-2"
                    onClick={performBasicSweetalert}
                  >
                    Basic Demo
                  </button>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-secondary mt-2"
                    onClick={performWithTextUnder}
                  >
                    With Text Under
                  </button>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-success mt-2"
                    onClick={performWithSuccess}
                  >
                    With Success
                  </button>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-danger mt-2"
                    onClick={performWithError}
                  >
                    With Error
                  </button>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-warning mt-5"
                    onClick={performWithConfirm}
                  >
                    With Confirm
                  </button>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-warning mt-5"
                    onClick={performWithThreeActions}
                  >
                    With Three Actions
                  </button>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-warning mt-5"
                    onClick={performWithCancle}
                  >
                    With Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactSweetAlert2Demo;
