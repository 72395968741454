import React from 'react'
import mockData from '../../Data/MOCK_DATA.json';

const ColourStatusCell = ({ getValue, row, column, table }) => {
  const initialColor = getValue()
  const { updateData } = table.options.meta
  return (
    <>
      <div className="btn-group">
        <button type="button" className="btn btn-sm btn-primary">{initialColor ? initialColor : "NA"}</button>
        <button type="button" className="btn btn-sm btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">
          <em className="icon ni ni-chevron-down"></em><span className="visually-hidden">Toggle Dropdown</span>
        </button>
        <div className="dropdown-menu">
          <ul className="link-list-opt no-bdr">
            {
              mockData.color_status?.map((api) => {
                return <li><a className="dropdown-item" href="#" onClick={(event) => {
                  event.preventDefault();
                  updateData(row.index,
                    column.id,
                    api.label)
                }}>{api.label}</a></li>
              })
            }
          </ul>
        </div>
      </div>



      {/* <div className="dropdown">
        <button className="btn btn-sm btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="true">
          {initialColor ? initialColor : "NA"}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {
            mockData.color_status?.map((api) => {
              return <li><a className="dropdown-item" href="#" onClick={(event) => {
                event.preventDefault();
                updateData(row.index,
                  column.id,
                  api.label)
              }}>{api.label}</a></li>
            })
          }
        </ul>
      </div> */}
    </>
  )
}

export default ColourStatusCell