import React, { useContext, useState } from 'react'
import { APIContext } from "../../Context/APIContext";
import { MasterContext } from '../../Context/MasterContext';
import Table from '../Wrapper/Table';

const Student = () => {
  // Search state (The name of state should always be "start with search and then file name")
  const [searchUser, setSearchUser] = useState("");

   // Options for filters. (This is just for example.) it can be change according to dependency and requirements
   const { favoriteColor,companyName } = useContext(MasterContext)

  // state use in Context file 
  const {
    rawUserDetails,
    getUserData,
    userEntries,
    userPageCount,
    setUserEntries,
    userCount,
    handleClick,
    isUserData,
    isUserLoading,
  } = useContext(APIContext);

  // Table header and data display keys are mentioned here. All parameters used in table to display data are mentioned here
  // const headers = [
  //   {
  //     header_name : "First Name",
  //     display_keyname : "first_name",
  //     display_classname : "title", 
  //     privilege:"",
  //     path:"/path",
  //     state:"",  // doubt
  //     id: "first_name",
  //     button_icon : "",
  //     type:"text",
  //     sorting : {
  //       is_sorting : false,
  //       asce_name : "",
  //       asce_by : "",
  //       desc_name : "",
  //       desc_by : ""
  //     },
  //   },
  //   {
  //     header_name : "Last Name",
  //     display_keyname : "last_name",
  //     display_classname : "title", 
  //     privilege:"",
  //     path:"/path",
  //     state:"",  // doubt
  //     id: "last_name",
  //     button_icon : "",
  //     type:"text",
  //     sorting : {
  //       is_sorting : false,
  //       asce_name : "Ascending",
  //       asce_by : "latest",
  //       desc_name : "Descending",
  //       desc_by : "oldest"
  //     },
  //   },
  //   {
  //     header_name : "Age",
  //     display_keyname : "age",
  //     display_classname : "tb-sub", 
  //     privilege:"",
  //     path:"",
  //     state:"",
  //     id: "age",
  //     button_icon : "",
  //     type:"text",
  //     sorting : {
  //       is_sorting : true,
  //       asce_name : "Ascending",
  //       asce_by : "asc",
  //       desc_name : "Descending",
  //       desc_by : "desc"
  //     },
  //   },
  //   {
  //     header_name : "Email Address",
  //     display_keyname : "email",
  //     display_classname : "tb-lead", 
  //     privilege:"",
  //     path:"/path",
  //     state:"",
  //     id: "email_address",
  //     button_icon : "",
  //     type:"text",
  //     sorting : {
  //       is_sorting : false,
  //       asce_name : "",
  //       asce_by : "",
  //       desc_name : "",
  //       desc_by : ""
  //     },
  //   },
  //   {
  //     header_name : "Date of Birth",
  //     display_keyname : "date_of_birth",
  //     display_classname : "tb-lead", 
  //     privilege:"",
  //     path:"",
  //     state:"",
  //     id: "birth_date",
  //     button_icon : "",
  //     type:"text",
  //     sorting : {
  //       is_sorting : false,
  //       asce_name : "",
  //       asce_by : "",
  //       desc_name : "",
  //       desc_by : ""
  //     },
  //   },
  //   {
  //     header_name : "Favorite Color",
  //     display_keyname : "favorite_color",
  //     display_classname : "tb-lead", 
  //     privilege:"",
  //     path:"/path",
  //     state:"",
  //     id: "hobbies",
  //     button_icon : "",
  //     type:"text",
  //     sorting : {
  //       is_sorting : false,
  //       asce_name : "",
  //       asce_by : "",
  //       desc_name : "",
  //       desc_by : ""
  //     },
  //   },
  //   {
  //     header_name : "Company Name",
  //     display_keyname : "company_name",
  //     display_classname : "tb-lead", 
  //     privilege:"",
  //     path:"/path",
  //     state:"",
  //     id: "company_name",
  //     button_icon : "",
  //     type:"text",
  //     sorting : {
  //       is_sorting : false,
  //       asce_name : "",
  //       asce_by : "",
  //       desc_name : "",
  //       desc_by : ""
  //     },
  //   },
  //   {
  //     header_name : "Edit",
  //     display_keyname : "",
  //     display_classname : "", 
  //     privilege:"edit-student",
  //     path:"/edit-student",
  //     state:"",
  //     id: "edit_student",
  //     button_icon : "ni-edit",
  //     type:"button",
  //     sorting : {
  //       is_sorting : false,
  //       asce_name : "",
  //       asce_by : "",
  //       desc_name : "",
  //       desc_by : ""
  //     },
  //   }
  // ]

  const headers = [
    {
      header_name : "Job Title",
      display_keyname : "job_opening.job_title",
      display_classname : "title", 
      privilege:"",
      path:"/path",
      state:"",  // doubt
      id: "job_title",
      button_icon : "",
      type:"text",
      sort_key : "job_title",
      sorting : {
        is_sorting : false,
        asce_name : "",
        asce_by : "",
        desc_name : "",
        desc_by : ""
      },
    },
    {
      header_name : "Department",
      display_keyname : "job_opening.department.department_name",
      display_classname : "title", 
      privilege:"",
      path:"/path",
      state:"",  // doubt
      id: "department_name",
      button_icon : "",
      type:"text",
      sort_key : "department_name",
      sorting : {
        is_sorting : false,
        asce_name : "Ascending",
        asce_by : "latest",
        desc_name : "Descending",
        desc_by : "oldest"
      },
    },
    {
      header_name : "Job Type",
      display_keyname : "job_type",
      display_classname : "tb-sub", 
      privilege:"",
      path:"",
      state:"",
      id: "job_type",
      button_icon : "",
      type:"text",
      sort_key : "job_type",
      sorting : {
        is_sorting : false,
        asce_name : "",
        asce_by : "",
        desc_name : "",
        desc_by : ""
      },
    },
    {
      header_name : "Vacancy",
      display_keyname : "no_of_vacancy",
      display_classname : "tb-lead", 
      privilege:"",
      path:"/path",
      state:"",
      id: "no_of_vacancy",
      button_icon : "",
      type:"text",
      sort_key : "no_of_vacancy",
      sorting : {
        is_sorting : false,
        asce_name : "",
        asce_by : "",
        desc_name : "",
        desc_by : ""
      },
    },
    {
      header_name : "Requisition Acceptance",
      display_keyname : "requisition_acceptance",
      display_classname : "tb-lead", 
      privilege:"",
      path:"",
      state:"",
      id: "requisition_acceptance",
      button_icon : "",
      type:"text",
      sort_key : "requisition_acceptance",
      sorting : {
        is_sorting : false,
        asce_name : "",
        asce_by : "",
        desc_name : "",
        desc_by : ""
      },
    },
    // {
    //   header_name : "Edit",
    //   display_keyname : "",
    //   display_classname : "", 
    //   privilege:"edit-student",
    //   path:"/edit-student",
    //   state:"",
    //   id: "edit_student",
    //   button_icon : "ni-edit",
    //   type:"button",
    //   sorting : {
    //     is_sorting : false,
    //     asce_name : "",
    //     asce_by : "",
    //     desc_name : "",
    //     desc_by : ""
    //   },
    // }
  ]

  // Filters display Options. 
  const dept_filters = [
    {
      filter_key : "requisition_acceptance",
      filter_name : "Requisition Acceptance",
      filter_option : favoriteColor
    },
    {
      filter_key : "department_id",
      filter_name : "Department Name",
      filter_option : companyName
    }
  ]

  return (
    <>
     <Table
      searchBox={searchUser}
      setSearchBox={setSearchUser}
      rawDetails={rawUserDetails}
      getFunctionData={getUserData}
      entries={userEntries}
      pageCount={userPageCount}
      setEntries={setUserEntries}
      count={userCount}
      handleClick={handleClick}
      isData={isUserData}
      isLoading={isUserLoading}
      title="User Details"   //heading title
      seachPlaceholder="Search using Job title, Department, Vacany, Position, Priority"   // placeholder name
      headers={headers}  // List of dict
      filters={dept_filters}
      create_button = "false"
      create_button_name = ""
      create_button_path = "/create-student"
     />
    </>
  )
}

export default Student