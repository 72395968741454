import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";

const data = [
  { date: "2023-01-01", price: 4000 },
  { date: "2023-01-02", price: 4200 },
  { date: "2023-01-03", price: 3900 },
  { date: "2023-01-04", price: 4500 },
  { date: "2023-01-05", price: 4300 },
  { date: "2023-01-06", price: 4700 },
  { date: "2023-01-07", price: 4800 },
];

const AreaChartDemo = () => {
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
        >
          {/* Gridlines */}
          <CartesianGrid strokeDasharray="3 3" />

          {/* X and Y Axes */}
          <XAxis
            dataKey="date"
            label={{ value: "Date", position: "insideBottom", offset: -5 }}
          />
          <YAxis
            label={{ value: "Price ($)", angle: -90, position: "insideLeft" }}
          />

          {/* Tooltip for data points */}
          <Tooltip
            labelFormatter={(label) => `Date: ${label}`}
            formatter={(value) => [`$${value}`, "Price"]}
          />

          {/* Legend */}
          <Legend verticalAlign="top" height={36} />

          {/* Area Definition */}
          <Area
            type="monotone"
            dataKey="price"
            stroke="#8884d8"
            fillOpacity={0.5}
            fill="#8884d8"
          />

          {/* Brush for zooming */}
          <Brush height={10} y={390} />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default AreaChartDemo;
