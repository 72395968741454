import React from 'react'

const FolderStructure = () => {
  return (
    <>
      <div className="nk-block">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h5 className="nk-block-title">Folder Structure</h5>
            <p>The default folder and files will remain the same. Please follow the folder structure and necessary files outlined below.</p>
          </div>
        </div>
        <div className="card card-preview">
          <div className="card-inner">
            <div className="folder-structure">
              <ul className="folder-tree">
                <li>
                  <span className="folder">📁 public </span>
                  <ul>
                    <li>
                      <span className="folder">📁 assets</span>
                      <ul>
                        <li>
                          <span className="folder">📁 css</span>
                          <ul>
                            <li>
                              <span className="file">📄 style.css</span>
                            </li>
                            <li>
                              <span className="fw-bold">All CSS files should be include here</span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span className="folder">📁 js</span>
                          <ul>
                            <li>
                              <span className="file">📄 script.js</span>
                            </li>
                            <li>
                              <span className="fw-bold">All JS files should be include here</span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span className="folder">📁 images</span>
                          <ul>
                            <li>
                              <span className="file">🖼️ images.png</span>
                            </li>
                            <li>
                              <span className="file">🖼️ logo.png </span>
                            </li>
                            <li>
                              <span className="fw-bold">All images files should be include here.</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span className="file">📄 index.html</span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="folder">📁 src</span>
                  <ul>
                    <li>
                      <span className="folder">📁 components</span>
                      <ul>
                        <li>
                          <span className="folder">📁 Authentication</span>
                          <ul>
                            <li>
                              <span className="file">📄 Login.js </span>
                            </li>
                            <li>
                              <span className="file">📄 Registration.js </span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span className="folder">📁 Base</span>
                          <ul>
                            <li>
                              <span className="file">📄 Base.js </span>
                            </li>
                            <li>
                              <span className="file">📄 Content.js </span>
                            </li>
                            <li>
                              <span className="file">📄 Footer.js </span>
                            </li>
                            <li>
                              <span className="file">📄 Header.js </span>
                            </li>
                            <li>
                              <span className="file">📄 Sidebar.js </span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span className="folder">📁 Context</span>
                          <ul>
                            <li>
                              <span className="file">📄 APIContext.js</span>
                            </li>
                            <li>
                              <span className="file">📄 MasterContext.js</span>
                            </li>
                            <li>
                              <span className='fw-bold'>All context files will be placed under the Context folder.</span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span className="folder">📁 Pages</span>
                          <ul>
                            <li>
                              <span className="folder">📁 FolderName</span>
                              <ul>
                                <li>
                                  <span className="file">📄 FolderName.js</span>
                                </li>
                                <li>
                                  <span className="file">📄 CreateFolderName.js</span>
                                </li>
                                <li>
                                  <span className="file">📄 EditFolderName.js</span>
                                </li>
                                <li>
                                  <span className="fw-bold">As per the requirements, the folder names and file names should be added here</span>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span className="folder">📁 Data</span>
                          <ul>
                            <li>
                              <span className="file">📄 Data.json </span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span className="file">📄 App.js</span>
                    </li>
                    <li>
                      <span className="file">📄 .env</span>
                    </li>
                    <li>
                      <span className="file">📄 index.js</span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span className='file'>🗒️ package-lock.json</span>
                </li>
                <li>
                  <span className='file'>🗒️ package.json</span>
                </li>
                <li>
                  <span className='file'>⚙️ .gitignore</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!-- .card-preview --> */}
      </div>
    </>
  )
}

export default FolderStructure