import React from 'react'

const Docker = () => {
  return (
    <>
      <div className="card">
        <div className="card-inner">
          <div className="row g-gs flex-lg-row-reverse">
            <div className="col-lg-2">
              <div className="dropdown">
                <a href="Dockerfile.txt" download="Dockerfile.txt" className="btn btn-primary btn-dim d-none d-sm-inline-flex"><em className="icon ni ni-download-cloud"></em><span className="d-none d-md-inline">Download</span></a>
                <a href="Dockerfile.txt" download="Dockerfile.txt" className="btn btn-icon btn-primary btn-dim d-sm-none"><em className="icon ni ni-download-cloud"></em></a>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="product-details entry me-xxl-3">
                <h3>Docker File</h3>
                <p>A Dockerfile simplifies running the project both locally and on a server by creating a consistent environment. It ensures that any issues or errors in the code can be identified and resolved locally before deployment, reducing the chances of environment-specific bugs in production.</p>
                <ol className="list list-group-numbered" style={{ fontSize: "15px" }}>
                  <li className='list-group-item'><b>Download the Dockerfile</b> by clicking the "Download" button provided.</li>

                  <li className='list-group-item'>The downloaded file will be in <span className="badge badge-dim bg-dark" style={{ fontSize: "14px" }}>.txt</span> format. <b>Rename</b> the file to <span className="badge badge-dim bg-dark" style={{ fontSize: "14px" }}>Dockerfile</span> (with no extension).</li>

                  <li className='list-group-item'><b>Move the Dockerfile</b> to your project’s root directory.</li>

                  <li className='list-group-item'><b>Open the Dockerfile</b> and replace <span class="badge badge-dim bg-dark" style={{ fontSize: "14px" }}>your_folder_name</span> with the actual name of your project folder.</li>

                  <li className='list-group-item'><b>Verify the Dockerfile</b> to ensure it matches your project setup, such as dependencies and environment configurations.</li>

                  <li className='list-group-item'>Once the file is set up, you can <b>build and run the Docker container</b> using the appropriate Docker commands.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Docker