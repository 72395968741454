import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";

// Sample data for the bar chart
const data = [
  { name: "Jan", sales: 4000, expenses: 2400 },
  { name: "Feb", sales: 3000, expenses: 1398 },
  { name: "Mar", sales: 2000, expenses: 9800 },
  { name: "Apr", sales: 2780, expenses: 3908 },
  { name: "May", sales: 1890, expenses: 4800 },
  { name: "Jun", sales: 2390, expenses: 3800 },
  { name: "Jul", sales: 3490, expenses: 4300 },
];

const BarChartDemo = () => {
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          {/* Gridlines */}
          <CartesianGrid strokeDasharray="3 3" />

          {/* X and Y Axes */}
          <XAxis
            dataKey="name"
            label={{ value: "Months", position: "insideBottom", offset: -5 }}
          />
          <YAxis
            label={{ value: "Amount ($)", angle: -90, position: "insideLeft" }}
          />

          {/* Tooltip for data points */}
          <Tooltip
            formatter={(value) => [`$${value}`, "Amount"]}
            labelFormatter={(label) => `Month: ${label}`}
          />

          {/* Legend */}
          <Legend verticalAlign="top" height={36} />

          {/* Bar Definitions */}
          <Bar dataKey="sales" fill="#0088FE" animationDuration={500} />
          <Bar dataKey="expenses" fill="#00C49F" animationDuration={500} />
          {/* Brush for zooming */}
          <Brush height={10} y={390} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default BarChartDemo;
