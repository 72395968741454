import React from "react";
const SVG = () => {

  return (
    <>
      {/* <div style={{ position: "relative" }}>
        <img src="./images/building.svg" alt="Building" width="600" height="400" />

        <div style={{ position: "absolute", top: "190px", left: "290px" }}>
          <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
            <circle r="10" cx="10" cy="10" fill="red" />
            <circle r="10" cx="50" cy="50" fill="red" />
          </svg>
        </div>
      </div> */}
      <svg width="600" height="400" xmlns="http://www.w3.org/2000/svg">
        {/* <!-- Embedding the external image correctly using href --> */}
        <image href="./images/building.svg" width="600" height="400" />

        {/* <!-- Adding the red circle --> */}
        <circle r="10" cx="100" cy="100" fill="red" />
        <circle r="10" cx="200" cy="300" fill="red" />
      </svg>


    </>
  );
};

export default SVG;
