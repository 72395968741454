import React from 'react'

const Nomencluture = () => {
  return (
    <>
      <div className="nk-block">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h5 className="nk-block-title">Naming Conventions</h5>
            <p>Follow the Naming conventions as per given below</p>
          </div>
        </div>
        <div className="row g-gs">
          <div className="col-sm-12 col-lg-12">
            <div className="card card-full">
              <div className="card-inner">
                <h5 className="fs-4">Folder and File Names</h5>
                <p>Folder and file names should follow the <span className='fw-bold fs-6'>PascalCase</span> format. Each word in the name should start with a capital letter without spaces or underscores.</p>
                <p>Example : 
                  <span className="badge badge-s ms-2 badge-dim bg-dark">StudentData</span>
                  <span className="badge badge-s ms-2 badge-dim bg-dark">ActivityLogs</span>
                  <span className="badge badge-s ms-2 badge-dim bg-dark">CreateStudent.js</span>
                  <span className="badge badge-s ms-2 badge-dim bg-dark">EditPosition.js</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-12">
            <div className="card card-full">
              <div className="card-inner">
                <h5 className="fs-4">Hooks and State Names</h5>
                <p>Hooks and state variables should use <span className='fw-bold fs-6'>camelCase</span>. This format starts with a lowercase letter, and subsequent words should start with a capital letter.</p>
                <p>Example : 
                  <span className="badge badge-s ms-2 badge-dim bg-dark">studentName</span>
                  <span className="badge badge-s ms-2 badge-dim bg-dark">isLoading</span>
                  <span className="badge badge-s ms-2 badge-dim bg-dark">tokenCount.js</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-12">
            <div className="card card-full">
              <div className="card-inner">
                <h5 className="fs-4">Variable Names</h5>
                <p>Variable names should follow the <span className='fw-bold fs-6'>snake_case</span> format, where all letters are lowercase, and words are separated by underscores.</p>
                <p>Example : 
                  <span className="badge badge-s ms-2 badge-dim bg-dark">student_name</span>
                  <span className="badge badge-s ms-2 badge-dim bg-dark">default_values</span>
                  <span className="badge badge-s ms-2 badge-dim bg-dark">target_cards.js</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-12">
            <div className="card card-full">
              <div className="card-inner">
                <h5 className="fs-4">Class Names (Styling)</h5>
                <p>Class names used in styling should adhere to <span className='fw-bold fs-6'>kebab-case</span>, with all lowercase letters and words separated by hyphens.</p>
                <p>Example : 
                  <span className="badge badge-s ms-2 badge-dim bg-dark">form-control</span>
                  <span className="badge badge-s ms-2 badge-dim bg-dark">form-label</span>
                  <span className="badge badge-s ms-2 badge-dim bg-dark">connection-active</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- .card-preview --> */}
      </div>
    </>
  )
}

export default Nomencluture