import React, { useState,useEffect } from 'react'
import {Link, NavLink,useLocation } from 'react-router-dom'

const Sidebar = () => {
  const location = useLocation();
  const [sidebarDocument,setSidebarDocument] = useState(true)
  const [sidebarTable,setSidebarTable] = useState(false)
  const [sidebarLibrary,setSidebarLibrary] = useState(false)
  const [sidebarForm,setSidebarForm] = useState(false)

  useEffect(() => {
    console.log(location.pathname.split("/"));
  }, [location.pathname]);
  
  return (
    <>
      <div className="nk-sidebar is-light nk-sidebar-fixed is-light "
        data-content="sidebarMenu"
      >
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-sidebar-brand">
            <a href="javascript:void(0)" className="logo-link nk-sidebar-logo">
              <img className="logo-light logo-img-lg" src="./images/React.png" srcSet="./images/React.png 2x" alt="logo" />
              <img className="logo-dark logo-img-lg" src="./images/React.png" srcSet="./images/React.png 2x"
                alt="logo-dark" />
              <img className="logo-small logo-img logo-img-small" src="./images/React.png"
                srcSet="./images/React.png 2x" alt="logo-small" />
            </a>
          </div>
          <div className="nk-menu-trigger me-n2">
            <a
              href="#"
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
        {/* <!-- .nk-sidebar-element --> */}
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar>
              <ul className="nk-menu">
                <li className={`${sidebarDocument ? "nk-menu-item has-sub active" : "nk-menu-item has-sub"}`}>
                  <Link href="javascript:void(0)" className="nk-menu-link nk-menu-toggle" onClick={() => setSidebarDocument(!sidebarDocument)}>
                    <span className="nk-menu-icon"><em className="icon ni ni-file-text"></em></span>
                    <span className="nk-menu-text">Documentation</span>
                  </Link>
                  <ul className="nk-menu-sub">
                    <li className="nk-menu-item">
                      <NavLink to="/" className="nk-menu-link"><span
                        className="nk-menu-text">Folder Structure</span></NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink to="/nomencluture" className="nk-menu-link"><span
                        className="nk-menu-text">Nomencluture</span></NavLink>
                    </li>
                  </ul>
                </li>

                <li className={`${sidebarTable ? "nk-menu-item has-sub active" : "nk-menu-item has-sub"}`}>
                  <a href="javascript:void(0)" className="nk-menu-link nk-menu-toggle" onClick={() => setSidebarTable(!sidebarTable)}>
                    <span className="nk-menu-icon"><em className="icon ni ni-table-view"></em></span>
                    <span className="nk-menu-text">Table</span>
                  </a>
                  <ul className="nk-menu-sub">
                    <li className="nk-menu-item">
                      <NavLink to="/student" className="nk-menu-link"><span
                        className="nk-menu-text">Student</span></NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink to="/basic-table" className="nk-menu-link"><span
                        className="nk-menu-text">Basic Tanstack Table</span></NavLink>
                    </li>
                  </ul>
                </li>

                <li className="nk-menu-item">
                  <NavLink to="/mqtt" className="nk-menu-link">
                    <span className="nk-menu-icon"><em className="icon ni ni-db"></em></span>
                    <span className="nk-menu-text">MQTT</span>
                  </NavLink>
                </li>

                <li className={`${sidebarLibrary ? "nk-menu-item has-sub active" : "nk-menu-item has-sub"}`}>
                  <Link class="nk-menu-link nk-menu-toggle" onClick={() => setSidebarLibrary(!sidebarLibrary)}>
                    <span class="nk-menu-icon">
                      <em class="icon ni ni-users"></em>
                    </span>
                    <span class="nk-menu-text">Libraries</span>
                  </Link>
                  <ul class="nk-menu-sub">
                    <li className="nk-menu-item">
                      <NavLink
                        to="/libraries/reactselect"
                        className="nk-menu-link"
                      >
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-bag"></em>
                        </span>
                        <span className="nk-menu-text">React Select</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/libraries/reactsecurestorage"
                        className="nk-menu-link"
                      >
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-bag"></em>
                        </span>
                        <span className="nk-menu-text">
                          React Secure Storage
                        </span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/libraries/reactsweetalert2"
                        className="nk-menu-link"
                      >
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-bag"></em>
                        </span>
                        <span className="nk-menu-text">React Sweetalert2</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/libraries/reactdatepicker"
                        className="nk-menu-link"
                      >
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-bag"></em>
                        </span>
                        <span className="nk-menu-text">React Date Picker</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/libraries/reactpdf"
                        className="nk-menu-link"
                      >
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-bag"></em>
                        </span>
                        <span className="nk-menu-text">React PDF</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/libraries/reactapexchart"
                        className="nk-menu-link"
                      >
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-bag"></em>
                        </span>
                        <span className="nk-menu-text">React Apex Chart</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/libraries/recharts"
                        className="nk-menu-link"
                      >
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-bag"></em>
                        </span>
                        <span className="nk-menu-text">Recharts</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/libraries/reacthelmet"
                        className="nk-menu-link"
                      >
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-bag"></em>
                        </span>
                        <span className="nk-menu-text">React Helmet</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/libraries/reactduallistbox"
                        className="nk-menu-link"
                      >
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-bag"></em>
                        </span>
                        <span className="nk-menu-text">React Dual List Box</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className={`${sidebarForm ? "nk-menu-item has-sub active" : "nk-menu-item has-sub"}`}>
                  <Link class="nk-menu-link nk-menu-toggle" onClick={() => setSidebarForm(!sidebarForm)}>
                    <span class="nk-menu-icon">
                      <em class="icon ni ni-users"></em>
                    </span>
                    <span class="nk-menu-text">Forms</span>
                  </Link>
                  <ul class="nk-menu-sub">
                    <li className="nk-menu-item">
                      <NavLink to="/forms/basicforms" className="nk-menu-link">
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-bag"></em>
                        </span>
                        <span className="nk-menu-text">Basic Forms</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="nk-menu-item">
                  <NavLink to="/docker" className="nk-menu-link">
                    <span className="nk-menu-icon"><em className="icon ni ni-db"></em></span>
                    <span className="nk-menu-text">Docker/ Ngnix File</span>
                  </NavLink>
                </li>

                {/* <li className="nk-menu-item">
                  <NavLink to="/svg" className="nk-menu-link">
                    <span className="nk-menu-icon"><em className="icon ni ni-db"></em></span>
                    <span className="nk-menu-text">SVG</span>
                  </NavLink>
                </li> */}
                {/* <!-- .nk-menu-item --> */}
              </ul>
              {/* <!-- .nk-menu --> */}
            </div>
            {/* <!-- .nk-sidebar-menu --> */}
          </div>
          {/* <!-- .nk-sidebar-content --> */}
        </div>
        {/* <!-- .nk-sidebar-element --> */}
      </div>
    </>
  );
};

export default Sidebar;
