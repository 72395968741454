import React from "react";
import { useForm } from "react-hook-form";

const CustomValidationDemo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    reset,
    watch,
  } = useForm({
    mode: "onChange", // Real-time validation feedback
  });

  // Watch the input values for real-time changes
  const dateValidate = (data) => {
    const date = watch("start_date");
    return new Date(date) <= new Date(data);
  };

  // Handle form submission
  const onSubmit = (data) => {
    alert("Start date: " + data.start_date + " End date: " + data.end_date);
    reset(); // Reset form after submission
  };
  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <label className="form-label">
          Start Date <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="date"
          className="form-control"
          id="start_date"
          {...register("start_date", { required: true })}
        />
        {errors.start_date && (
          <span style={{ color: "red", fontSize: "0.8rem" }}>
            Start Date is required
          </span>
        )}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <label className="form-label">
          End Date <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="date"
          className="form-control"
          id="end_date"
          {...register("end_date", {
            required: true,
            validate: { dateValidate },
          })}
        />
        {errors.end_date && errors.end_date.type === "required" && (
          <span style={{ color: "red", fontSize: "0.8rem" }}>
            End Date is required
          </span>
        )}
        {errors.end_date && errors.end_date.type === "dateValidate" && (
          <span style={{ color: "red", fontSize: "0.8rem" }}>
            End date should be greater than or equal to start date
          </span>
        )}
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex align-items-center">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default CustomValidationDemo;
