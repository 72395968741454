import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ReactDatePickerDemo = () => {
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">React Date Picker</h3>
            <p className="lead">For more details, visit this <a className="text-info" href="https://www.npmjs.com/package/react-datepicker" target="_blank">React Date Picker</a></p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Basic Date Picker</label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Time Picker</label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      showTimeSelect
                      dateFormat="Pp"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Date Range Picker</label>
                  <div className="d-flex align-items-center g-1">
                    <DatePicker
                    className="form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                    />
                    {":"}
                    <DatePicker
                    className="form-control"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">
                    Disable Specific Dates (Disable weekends)
                  </label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      filterDate={(date) =>
                        date.getDay() !== 0 && date.getDay() !== 6
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Custom Date Format</label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Min/Max Date</label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      minDate={new Date()} // Can't select past dates
                      maxDate={new Date("2024-12-31")} // Set max date
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Month and Year Dropdowns</label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Highlight Specific Dates</label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      highlightDates={[
                        new Date("2024-09-25"),
                        new Date("2024-10-01"),
                      ]}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Year Picker</label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Multiple Months</label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      monthsShown={2}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Quarter Picker</label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      showQuarterYearPicker
                      dateFormat="yyyy, QQQ"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Week Numbers</label>
                  <div>
                    <DatePicker
                    className="form-control"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      showWeekNumbers
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactDatePickerDemo;
