import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Student from '../Pages/Student/Student'
import CreateStudent from '../Pages/Student/CreateStudent'
import EditStudent from '../Pages/Student/EditStudent'
import BasicTable from '../Pages/Tanstackv8/BasicTable'
import ReactMQTT from '../Pages/MQTT/ReactMQTT'
import FolderStructure from '../Pages/Documentation/FolderStructure'
import Nomencluture from '../Pages/Documentation/Nomencluture'
import LibPagesLayout from "../Pages/libraries/LibPagesLayout";
import ReactSelectDemo from "../Pages/libraries/react-select-demo/ReactSelectDemo";
import ReactSecureStorageDemo from "../Pages/libraries/react-secure-storage-demo/ReactSecureStorageDemo";
import ReactSweetAlert2Demo from "../Pages/libraries/react-sweetalert2-demo/ReactSweetAlert2Demo";
import ReactDatePickerDemo from "../Pages/libraries/react-datepicker-demo/ReactDatePickerDemo";
import ReactPDFDemo from "../Pages/libraries/react-pdf-demo/ReactPDFDemo";
import ReactApexChartDemo from "../Pages/libraries/react-apexchart-demo/ReactApexChartDemo";
import ReactHelmetDemo from "../Pages/libraries/react-helmet-demo/ReactHelmetDemo";
import ReactRechartsDemo from "../Pages/libraries/react-recharts-demo/ReactRechartsDemo";
import FormPagesLayout from "../Pages/forms/FormPagesLayout";
import BasicFormsDemo from "../Pages/forms/basic-forms/BasicFormsDemo";
import Documentation from '../Pages/Documentation/Documentation'
import ColumnOrdering from '../Pages/Tanstackv8/ColumnOrdering'
import ReactDualListBoxDemo from '../Pages/libraries/react-dual-listbox-demo/ReactDualListBoxDemo'
import DockerizedFile from '../Pages/DockerizedFile/DockerizedFile'
import Error from '../Pages/NotFound/Error'
import SVG from '../Pages/SVG'

const Content = () => {
  return (
    <>
      <div className="nk-content nk-content-fluid">
        <div className="container-xl wide-xl">
          <div className="nk-content-body">
            <Routes>
              <Route path='/' element={<Documentation />}>
                <Route path='/' element={<FolderStructure />} />
                <Route path='/nomencluture' element={<Nomencluture />} />
              </Route>

              <Route path='/student' element={<Student />} />
              <Route path='/create-student' element={<CreateStudent />} />
              <Route path='/edit-student' element={<EditStudent />} />

              <Route path='/basic-table' element={<BasicTable />} />
              <Route path='/mqtt' element={<ReactMQTT />} />

              <Route path="/libraries" element={<LibPagesLayout />}>
                <Route path="reactselect" element={<ReactSelectDemo />} />
                <Route path="reactsecurestorage" element={<ReactSecureStorageDemo />} />
                <Route path="reactsweetalert2" element={<ReactSweetAlert2Demo />} />
                <Route path="reactdatepicker" element={<ReactDatePickerDemo />} />
                <Route path="reactpdf" element={<ReactPDFDemo />} />
                <Route path="reactapexchart" element={<ReactApexChartDemo />} />
                <Route path="reacthelmet" element={<ReactHelmetDemo />} />
                <Route path="recharts" element={<ReactRechartsDemo />} />
                <Route path="reactduallistbox" element={<ReactDualListBoxDemo />} />
              </Route>
              
              <Route path="/forms" element={<FormPagesLayout />}>
                <Route path="basicforms" element={<BasicFormsDemo />} />
              </Route>

              <Route path='/change-column-ordering' element={<ColumnOrdering />} />

              <Route path='/docker' element={<DockerizedFile />} />
              <Route path='/svg' element={<SVG />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
