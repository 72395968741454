import React, { useState } from "react";
import Chart from "react-apexcharts";

const BarChart = () => {
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%", // Adjust the bar width
      },
    },
    dataLabels: {
      enabled: true, // Show data labels on the bars
    },
    xaxis: {
      categories: ["Q1", "Q2", "Q3", "Q4"],
    },
    legend: {
      position: "top", // Legend on top
      horizontalAlign: "center",
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Revenue",
      data: [44, 55, 57, 56],
    },
    {
      name: "Profit",
      data: [35, 41, 36, 26],
    },
  ]);

  return (
    <div>
      <Chart options={options} series={series} type="bar" height="400" />
    </div>
  );
};

export default BarChart;
