import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./react-pdf-demo.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const ReactPDFDemo = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleError = (error) => {
    console.error("PDF Error:", error);
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">React PDF</h3>
            <p className="lead">For more details, visit this <a className="text-info" href="https://www.npmjs.com/package/react-pdf" target="_blank">React PDF</a></p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="row d-flex">
                <Document
                  file="https://assets.ctfassets.net/v228i5y5k0x4/5TQiVwUV1KKUq8oauiW468/64427be1c0bba9a609bd7c0b7c73a77b/Japan.pdf"
                  // file={
                  //   "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_resume/Professional_CV_Resume_w52B3PC.pdf"
                  // }
                  onLoadSuccess={onDocumentLoadSuccess}
                  onError={handleError}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <p className="mt-3 d-flex align-items-center justify-content-center">
                  Page {pageNumber} of {numPages}
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-primary me-3"
                    disabled={pageNumber <= 1}
                    onClick={() => setPageNumber(pageNumber - 1)}
                  >
                    Previous
                  </button>
                  <button
                    className="btn btn-primary"
                    disabled={pageNumber >= numPages}
                    onClick={() => setPageNumber(pageNumber + 1)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactPDFDemo;
