import React from "react";
import { useForm } from "react-hook-form";

const TextInputFormDemo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, touchedFields },
    reset,
    watch,
  } = useForm({
    mode: "onChange", // Real-time validation feedback
    defaultValues: { username: "", phoneNumber: "" }, // Default values for the form fields
  });

  // Watch the input values for real-time changes
  const username = watch("username");
  const phoneNumber = watch("phoneNumber");

  // Handle form submission
  const onSubmit = (data) => {
    console.log("Form Data:", data);
    alert(
      "Submitted username: " +
        data?.username +
        " Phone No: " +
        data?.phoneNumber
    );
    reset(); // Reset form after submission
  };
  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <label className="form-label">Input Username</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter your username"
          {...register("username", {
            required: "Username is required", // Validation rule for required field
            minLength: {
              value: 3,
              message: "Username must be at least 3 characters",
            },
            maxLength: {
              value: 20,
              message: "Username cannot exceed 20 characters",
            },
            pattern: {
              value: /^[A-Za-z]+$/i,
              message: "Username must contain only letters",
            },
          })}
        />
        {errors.username && (
          <span style={{ color: "red", fontSize: "0.8rem" }}>
            {errors.username.message}
          </span>
        )}

        {username && !errors.username && touchedFields.username && (
          <span style={{ color: "green", fontSize: "0.8rem" }}>
            Looks good!
          </span>
        )}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <label className="form-label">Input Phone Number</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter your phone number"
          {...register("phoneNumber", {
            required: "Phone number is required", // Validation rule for required field
            pattern: {
              value: /^[0-9]{10}$/, // Simple pattern for a 10-digit phone number
              message:
                "Phone number must be exactly 10 digits and contain only numbers",
            },
          })}
        />

        {/* Error Message */}
        {errors.phoneNumber && (
          <span style={{ color: "red", fontSize: "0.8rem" }}>
            {errors.phoneNumber.message}
          </span>
        )}

        {/* Real-time Feedback */}
        {phoneNumber && !errors.phoneNumber && touchedFields.phoneNumber && (
          <span style={{ color: "green", fontSize: "0.8rem" }}>
            Phone number looks good!
          </span>
        )}
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex align-items-center">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default TextInputFormDemo;
