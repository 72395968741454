import React, { useEffect, useState, useRef } from 'react';
//Library to connect mqtt connection
import Paho from 'paho-mqtt';
import { v4 as uuidv4 } from 'uuid';

const ReactMQTT = () => {
  const mqtt_client_id = process.env.REACT_APP_MQTT_KEY
  // after connected to mqtt - stores the mqtt details in "client" state
  const [client, setClient] = useState(null);
  // update the connected/ disconnected status of MQTT in "isConnected" state
  const [isConnected, setIsConnected] = useState(false);
  // Stores the publish acknowledge message in the state "pubAckMessage"
  const [pubAckMessage, setPubAckMessage] = useState('');

  const [publishMessage, setPublishMessage] = useState(null)

  useEffect(() => {
    mqttConnection()
  }, []);

  const mqttConnection = () => {
    const mqttClient = new Paho.Client('wss://mqtt.elorca.com:9001/mqtt', `REACT-${mqtt_client_id}-MQTT`);
    // Note : Instead of "mqtt.elorca.com:9001" topic, you can used your topic to subscribe and publish

    // Function to connect with a delay after a disconnection
    mqttClient.connect({
      keepAliveInterval: 60,
      onSuccess: () => {
        console.log('Connected to MQTT broker');
        setClient(mqttClient);
        setIsConnected(true);

        // Subscribe to the topic after connection
        const mqtt_topic = 'test/topic';
        mqttClient.subscribe(mqtt_topic, { qos: 2 })
      },
      useSSL: true,
      onFailure: (error) => {
        console.error(`Connection failed - ErrorCode: ${error.errorCode}, ErrorMessage: ${error.errorMessage}`);
        setIsConnected(false);
      },
    });

    // "mqttClient.onConnectionLost" checks whether the MQTT connection is lost or if there is an error.
    mqttClient.onConnectionLost = (responseObject) => {
      if (responseObject.errorCode !== 0) {
        console.log(`Connection lost: ${responseObject.errorMessage}`);
        setIsConnected(false);
      }
    };

    // "mqttClient.onMessageDelivered - successfully delivers a message to the broker" 
    mqttClient.onMessageDelivered = (message) => {
      console.log('PUBACK received for message:', message.payloadString);
      setPubAckMessage(`Message received : ${message.payloadString}`);
    };

    // "mqttClient.onMessageArrived" - message is received from the broker on a subscribed topic
    mqttClient.onMessageArrived = (message) => {
      console.log('Message Arrived:', message.payloadString);
    };

    // Cleanup reconnect timeout on unmount
    return () => {
      if (mqttClient.isConnected()) mqttClient.disconnect();
      setIsConnected(false);
    };
  }

  // Function to send message on the subscribe topic
  const sendPublishMessage = (input_value) => {
    if (client && client.isConnected()) {
      setIsConnected(true);
      const mqtt_topic = 'test/topic';
      // client.publish("mqtt_topic, value to pass, QoS, retain value")
      client.publish(mqtt_topic, input_value, 2, true, (error) => {
        if (error) {
          console.error('Error publishing to topic:', error);
        } else {
          console.log(`Published to ${mqtt_topic}: ${input_value}`);
        }
      });
    } else {
      console.log('Connection lost');
      setIsConnected(false);
    }
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">MQTT Setup Demo - {isConnected ? <span className='text-success'> Connected </span> : <span className='text-danger'> Disconnected </span>} 
            <button className="btn ms-3 btn-sm btn-outline-info" data-bs-toggle="tooltip" data-bs-placement="right" title="Status disconnect then refresh the button to connect mqtt." onClick={() => mqttConnection()} ><em class="icon ni ni-reload"></em></button></h3>
            <p className="lead">MQTT subscribe and publish topic - "test/topic"</p>
          </div>
        </div>
      </div>

      <div className='nk-block'>
        <div className='row g-gs'>
          <div className='col-lg-6 col-md-12 col-sm-12'>
            <div className="card pricing">
              <div className="pricing-body">
                <div class="form-group">
                  <div class="form-control-wrap">
                    <label className='form-label'> Publishing to the Topic - "test/topic" </label>
                    <input type="text" class="form-control" id="default-01" placeholder="Enter message to publish" onChange={(e) => setPublishMessage(e.target.value)} />
                  </div>
                </div>
                <div className="pricing-action">
                  <button className='btn btn-info' onClick={() => sendPublishMessage(publishMessage)}>Publish Message</button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12'>
            <div className="card pricing">
              <div className="pricing-body">
                <div className="pricing-amount">
                  <p>Subscribing to the Topic - "test/topic" </p>
                  <div className="amount">{pubAckMessage} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactMQTT;