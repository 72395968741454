import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Login from "./Components/Authentication/Login";
import Base from "./Components/Base/Base";
import { ChakraProvider } from '@chakra-ui/react'

const App = () => {
  //change
  const [isLoggedIn, setIsLoggedIn] = useState(true)

  // Set values in localstorage,
  const loginHandler = (data) => {
    console.log(data);
    secureLocalStorage.setItem("isLoggedIn", "USER_LOGGED_IN");

    // store your localstorage details here
    // secureLocalStorage.setItem("userID", data.user.id);
    setIsLoggedIn(true);
  };

  const logoutHandler = () => {
    secureLocalStorage.clear();
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const userStatus = secureLocalStorage.getItem("isLoggedIn");
    if (userStatus === "USER_LOGGED_IN") {
      setIsLoggedIn(true);
    } else if (userStatus === "USER_LOGGED_OUT") {
      setIsLoggedIn(false);
    }
  }, []);
  return (
    <>
      <ChakraProvider>
        <BrowserRouter>
          {isLoggedIn && <Base logoutHandler={logoutHandler} />}
          {!isLoggedIn && <Login loginHandler={loginHandler} />}
        </BrowserRouter>
      </ChakraProvider>
    </>
  )
}

export default App