import React, { useState } from 'react'
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const single_options = [
  { value: 'one', label: 'Option One' },
  { value: 'two', label: 'Option Two' },
];

const disabled_options = [
  { value: 'Orange', label: 'Orange', disabled:true },
  { value: 'Apple', label: 'Apple' },
];

const multiple_options = [
  {
      label: 'Earth',
      options: [
          { value: 'luna', label: 'Moon' },
      ],
  },
  {
      label: 'Mars',
      options: [
          { value: 'phobos', label: 'Phobos' },
          { value: 'deimos', label: 'Deimos' },
      ],
  },
  {
      label: 'Jupiter',
      options: [
          { value: 'io', label: 'Io' },
          { value: 'europa', label: 'Europa' },
          { value: 'ganymede', label: 'Ganymede' },
          { value: 'callisto', label: 'Callisto' },
      ],
  },
];

const ReactDualListBoxDemo = () => {

  const [selected, setSelected] = useState([]);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">React Dual List Box</h3>
            <p className="lead">For more details, visit this <a className="text-info" href="https://www.npmjs.com/package/react-dual-listbox" target="_blank">React Dual List Box</a></p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Basic Dual List Box</label>
                  <div>
                    <DualListBox
                      options={single_options}
                      selected={selected}
                      onChange={(newValue) => setSelected(newValue)}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Nested Dual List Box with Filter</label>
                  <div>
                    <DualListBox
                      options={multiple_options}
                      selected={selected}
                      canFilter
                      onChange={(newValue) => setSelected(newValue)}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Dual List Box with Disable options</label>
                  <div>
                    <DualListBox
                      options={disabled_options}
                      selected={selected}
                      onChange={(newValue) => setSelected(newValue)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReactDualListBoxDemo