import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";

const ReactSecureStorageDemo = () => {
  const [key, setKey] = useState("");
  const [storedKey, setStoredKey] = useState("");
  const handleSetKey = () => {
    if (key !== "") {
      secureLocalStorage.setItem("key", key);
    }
  };
  const handleGetKey = () => {
    const stored_key = secureLocalStorage.getItem("key");
    console.log(stored_key);
    if (stored_key !== null) {
      setStoredKey(stored_key);
    } else {
      setStoredKey("");
    }
  };
  const handleRemoveKey = () => {
    secureLocalStorage.removeItem("key");
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">React Secure Storage</h3>
            <p className="lead">For more details, visit this <a className="text-info" href="https://www.npmjs.com/package/react-secure-storage" target="_blank">Secure Local Storage</a></p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Set Key</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Key"
                    onChange={(e) => setKey(e.target.value)}
                  />
                  <button
                    className="btn btn-primary mt-2"
                    onClick={handleSetKey}
                  >
                    Store Key
                  </button>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Stored Key</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Get key"
                    value={storedKey}
                    disabled
                  />
                  <button
                    className="btn btn-primary mt-2"
                    onClick={handleGetKey}
                  >
                    Get Key
                  </button>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <button
                    className="btn btn-danger mt-2"
                    onClick={handleRemoveKey}
                  >
                    Remove Key
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactSecureStorageDemo;
