import React from 'react'

const Header = (props) => {
  return (
    <>
      <div className="nk-header is-light nk-header-fixed is-light">
        <div className="container-xl wide-xl">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ms-n1 me-3">
              <a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em
                className="icon ni ni-menu"></em></a>
            </div>
            <div className="nk-header-brand d-xl-none">
              <a href="html/index.html" className="logo-link">
                <img className="logo-light logo-img" src="./images/React.png" srcSet="./images/React.png 2x" alt="logo" />
                  <img className="logo-dark logo-img" src="./images/React.png" srcSet="./images/React.png 2x"
                    alt="logo-dark" />
                  </a>
                </div>
                {/* <!-- .nk-header-brand --> */}
                <div className="nk-header-tools">
                  <ul className="nk-quick-nav">
                    <li className="dropdown user-dropdown">
                      <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown">
                        <div className="user-toggle">
                          <div className="user-avatar sm">
                            <em className="icon ni ni-user-alt"></em>
                          </div>
                        </div>
                      </a>
                      <div className="dropdown-menu dropdown-menu-md dropdown-menu-end">
                        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                          <div className="user-card">
                            <div className="user-avatar">
                              <span>VU</span>
                            </div>
                            <div className="user-info">
                              <span className="lead-text">Vaishnavi</span>
                              <span className="sub-text">vaishnavi@techwalnut.com</span>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown-inner">
                          <ul className="link-list">
                            <li><a href="#" onClick={() => props.logoutHandler}><em className="icon ni ni-signout"></em><span>Sign out</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <!-- .nk-header-tools --> */}
            </div>
            {/* <!-- .nk-header-wrap --> */}
          </div>
          {/* <!-- .container-fliud --> */}
        </div>
      </>
      )
}

      export default Header