import React, { useContext, useEffect, useState } from 'react'
import {
  flexRender, getCoreRowModel, useReactTable, getSortedRowModel, getFilteredRowModel,
  getPaginationRowModel
} from '@tanstack/react-table'
import mockData from '../../Data/MOCK_DATA.json'
import Select from "react-select";
import { columns } from './Columns'
import Filter from './FilterFunction'
import { NavLink } from 'react-router-dom'
import { StateContext } from '../../Context/StateContext'

const BasicTable = () => {
  const [data, setData] = useState(mockData.mockData)
  const [sorting, setSorting] = useState([])
  const [filter, setFilter] = useState("")
  const [columnFilters, setColumnFilters] = useState([])
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({age: false,company_name:false})
  const { columnOrder, setColumnOrder } = useContext(StateContext)

  const [selectColor, setSelectColor ] = useState([])
  console.log(selectColor)

  const [firstName, setFirstName ] = useState([])
  console.log(firstName)

  const table = useReactTable({
    data,
    columns,
    initialState: {
      // columnOrder: ['id', 'first_name', 'last_name', 'email', 'date_of_birth', 'favorite_color'], //customize the initial column order
      columnVisibility: {
        age: false //hide the id column by default
      },
    },
    state: {
      sorting: sorting,
      globalFilter: filter,
      columnFilters: columnFilters,
      rowSelection: rowSelection,
      columnOrder: columnOrder,
      columnVisibility: columnVisibility
    },
    enableSortingRemoval: false,  // process to go as none -> desc -> asc -> desc -> asc -> ..
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), // client side sorting
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // manualSorting: true, // if you are using server side sorting
    onSortingChange: setSorting,
    onGlobalFilterChanged: setFilter,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    enableRowSelection: true,
    meta: {
      updateData: (rowIndex, columnId, value) =>
        setData((prev) =>
          prev.map((row, index) =>
            index === rowIndex
              ? {
                ...prev[rowIndex],
                [columnId]: value,
              }
              : row
          )
        )
    }
  })

  // console.log(table.getSelectedRowModel())

  // Get the sorting state to pass in api.
  console.log(table.getState().sorting)
  console.log(table.getFilteredRowModel())

  useEffect(() => {
    table.getHeaderGroups().map(headersArr => {
      headersArr.headers.map(columnObject => {

        if (columnObject.column.id === 'favorite_color') {
          columnObject.column.setFilterValue(selectColor?.value);
        }

        if (columnObject.column.id === 'first_name') {
          columnObject.column.setFilterValue(firstName?.value);
        }
      })

    })
  }, [selectColor,firstName]);

  return (
    <>
      {/* <label>
        <input
          {...{
            type: "checkbox",
            checked: table.getIsAllColumnsVisible(),
            onChange: table.getToggleAllColumnsVisibilityHandler(),
          }}
        />
        Toggle All
      </label> */}
      {/* {table.getAllLeafColumns().map((column) => {
        return (
          <div className='d-flex' key={column.id}>
            <label>
              <input
                {...{
                  type: "checkbox",
                  checked: column.getIsVisible(),
                  onChange: column.getToggleVisibilityHandler(),
                }}
              />{" "}
              {column.id}
            </label>
          </div>
        );
      })} */}
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Basic Table</h3>
          </div>
          <div className="nk-block-head-content d-flex">
            <Select options={mockData.color_status} 
            onChange={setSelectColor}
            isClearable />
          </div>
          {/* <div className="nk-block-head-content d-flex">
            <Select options={[
              {value:"Latia", label: "Latia"},
              {value:"Vicky", label: "Vicky"}
            ]}
            onChange={setFirstName}
            isClearable />
          </div> */}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <div className="input-group">
                  <input
                    type="text"
                    value={filter}
                    className="form-control"
                    placeholder="Search by table column"
                    onChange={(e) => setFilter(e.target.value)}
                    id="text-form-input-search" />
                </div>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    {table.getHeaderGroups().map(headerGroup => {
                      return <div className="nk-tb-item nk-tb-head" key={headerGroup.id}>
                        {headerGroup.headers.map(header => ( // map over the headerGroup headers array
                          <div className="nk-tb-col" key={header.id}>
                            <div onClick={header.column.getToggleSortingHandler()}>
                              <span className="d-flex align-center">
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {header.column.getCanSort() && (
                                  {
                                    asc: " 🔼",
                                    desc: " 🔽",
                                  }[header.column.getIsSorted()] || " ↕️"
                    )}
                              </span>
                            </div>
                            <div>
                              {/* {header.column.getCanFilter() ? <Filter column={header.column} table={table} /> : null } */}
                              {header.column.getCanFilter() ?
                                <input type='text' className='form-control' value={header.column.getFilterValue() || ''}
                                placeholder={`Search by ${header.column.columnDef.header}`}
                                  onChange={(e) => header.column.setFilterValue(e.target.value)} /> : null
                              }
                            </div>
                          </div>
                        ))}
                      </div>
                    })}


                    {table.getRowModel().rows.map(row => (
                      <div className="nk-tb-item" key={row.id}>
                        {row.getVisibleCells().map(cell => {
                          return <div className="nk-tb-col" key={cell.id}>
                            <span className="tb-sub">{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                          </div>
                        })}
                      </div>
                    ))}


                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <button className='btn btn-primary-outline me-2'
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<<"}
                  </button>
                  <button className='btn btn-primary-outline me-2'
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    Previous Page
                  </button>
                  <button className='btn btn-primary-outline me-2'
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    Next Page
                  </button>
                  <button className='btn btn-primary-outline me-2'
                    onClick={() =>
                      table.setPageIndex(table.getPageCount() - 1)
                    }
                    disabled={!table.getCanNextPage()}
                  >
                    {">>"}
                  </button>
                </div>
                <div className="g">
                  <ul>
                    <li>
                      You are on page number:{" "}
                      {table.options.state.pagination.pageIndex + 1}
                    </li>
                    <li>Total pages: {table.getPageCount()}</li>
                  </ul>
                </div>
                {/* <input
              type="number"
              defaultValue={table.options.state.pagination.pageIndex}
              onChange={(e) => table.setPageIndex(e.target.value)}
            /> */}
                <div className="g">
                  <select
                    value={table.options.state.pagination.pageSize}
                    onChange={(e) => table.setPageSize(e.target.value)}
                  >
                    {[10, 25, 50].map((pageSizeEl) => {
                      return (
                        <option key={pageSizeEl} value={pageSizeEl}>
                          {pageSizeEl}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="form-note-s2 pt-4"> Change Column Ordering ?
          <NavLink className='text-primary'
            to="/change-column-ordering"
          ><strong> Click here to change the table columns</strong></NavLink>
        </div>
      </div >
    </>
  )
}

export default BasicTable