import React from "react";
import Select from "react-select";

const options = [
  { value: "new_york", label: "New York" },
  { value: "los_angeles", label: "Los Angeles" },
  { value: "chicago", label: "Chicago" },
];

const ReactSelectDemo = () => {
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">React Select</h3>
            <p className="lead">For more details, visit this <a className="text-info" href="https://react-select.com/home" target="_blank">React Select</a></p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">
                    Basic react select dropdown
                  </label>
                  <Select options={options} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Multi select dropdown</label>
                  <Select options={options} isMulti />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">
                    Multi select with clearable dropdown
                  </label>
                  <Select options={options} isMulti isClearable />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">With placeholder</label>
                  <Select options={options} placeholder={"Select a city..."} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">With default selected</label>
                  <Select options={options} defaultValue={options[1]} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">With loading</label>
                  <Select options={[]} isLoading={true} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">Disables</label>
                  <Select
                    options={options}
                    defaultValue={options[2]}
                    isDisabled={true}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <label className="form-label">With action</label>
                  <Select
                    options={options}
                    onChange={(data) => alert(`You have select ${data.label}`)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactSelectDemo;
