import React from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

const options = [
  { value: "javascript", label: "JavaScript" },
  { value: "python", label: "Python" },
  { value: "java", label: "Java" },
  { value: "csharp", label: "C#" },
];

const DropdownFormDemo = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      language: "",
      frameworks: [],
    },
    mode: "onChange", // Provides real-time validation feedback
  });

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    alert("Selected language is: " + data.language.value);
    reset(); // Reset form after successful submission
  };

  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <label className="form-label">Input Username</label>
        <Controller
          name="language"
          control={control}
          rules={{ required: "Please select a programming language" }}
          render={({ field }) => (
            <Select
              {...field}
              options={options}
              placeholder="Select a language"
              isClearable
            />
          )}
        />
        {errors.language && (
          <span style={{ color: "red", fontSize: "0.8rem" }}>
            {errors.language.message}
          </span>
        )}
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex align-items-center">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default DropdownFormDemo;
