import React from 'react'
import { Outlet } from 'react-router-dom'

const Documentation = () => {
  return (
    <>
    <Outlet />
    </>
  )
}

export default Documentation