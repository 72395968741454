import React, { useContext } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Swal from "sweetalert2";
import { MasterContext } from "../../Context/MasterContext";
import { validate } from "uuid";
import { isValidEmail } from "../../ValidationUtilities/ValidationUtilities";

const CreateStudent = () => {
  const env_url = process.env.REACT_APP_API_URL
  // react Hook form Props
  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();

  // Dropdown options in "MasterContext"
  const {
    hobbies, educationName, collegeName
  } = useContext(MasterContext)

  // onSubmit handler to post form details
  //  form_data will get change according to the payload
  const onSubmit = (data) => {
    // The Payload depends on weather it is "application/json" type or "Form Data"
    // Refer Postman collection for payload confirmation
    let form_data = new FormData();
    form_data.append("student_name", data.student_name);
    form_data.append("age", data.age);
    form_data.append("birth_date", data.date_birth);
    form_data.append("mobile_number", data.mobile_number);
    form_data.append("address", data.address);
    form_data.append("email_address", data.email_address);
    form_data.append("hobbies", data.hobbies.value);
    form_data.append("college_school_name", data.college_school_name.value);
    form_data.append("education", data.education_name.value);
    form_data.append("grades", data.grades);
    form_data.append("marksheet", data.marksheet);

    const url = env_url + "/api/students/";
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      // If the payload is in "form data" remove the 'Content-Type': 'application/json' from headers
      data: form_data,
    };

    axios(config).then((response) => {
      console.log(JSON.stringify(response.data));
      // Here sweetalert is use. (You can use any UI to display message)
      // Depends on Client Requirements too

      Swal.fire({
        title: "Student Added!",
        text: "The student details have been successfully added.",
        icon: "success"
      });
      reset();
    }).catch((error) => {
      // Here sweetalert is use. (You can use any UI to display message)
      // Depends on Client Requirements too

      // In Error, You can also pass "response.message" in text field 
      // for better understanding
      Swal.fire({
        title: "Error Adding Student",
        text: "There was an issue adding the student details. Please try again.",
        icon: "error"
      });
      console.log(error);
    })
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Student Details </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Student Name<span className="fs-8 text-danger"> *</span></label>
                  <input type="text" className="form-control"
                    placeholder="Enter student name"
                    id="text-forms-student_name"
                    {...register("student_name", { required: true })}
                  />
                  {errors.student_name && (<span className="fs-8 text-danger"> Student Name is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Email Address<span className="fs-8 text-danger"> *</span></label>
                  <input type="email" className="form-control"
                    placeholder="Enter your email address"
                    id="email-forms-email_address"
                    {...register("email_address", {
                      required : true,
                      validate : isValidEmail})}
                  />
                  {errors.email_address && errors.email_address.type === "required" && (<span className="fs-8 text-danger"> Email Address is required</span>
                  )}
                  {errors.email_address && errors.email_address.type === "validate" && <span className="fs-8 text-danger">{errors.email_address.message || "Correct the format"}</span>}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Age<span className="fs-8 text-danger"> *</span></label>
                  <input type="number" className="form-control"
                    placeholder="Enter Age of student"
                    id="number-forms-age"
                    {...register("age", { required: true })}
                  />
                  {errors.age && (<span className="fs-8 text-danger"> Age is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Birth Date<span className="fs-8 text-danger"> *</span></label>
                  <input type="date" className="form-control"
                    placeholder="Enter date of birth"
                    id="date-forms-date_birth"
                    {...register("date_birth", { required: true })}
                  />
                  {errors.date_birth && (
                    <span className="fs-8 text-danger">Date of Birth is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Address<span className="fs-8 text-danger"> *</span></label>
                  <input type="text" className="form-control"
                    placeholder="Enter address"
                    id="textarea-forms-address"
                    {...register("address", { required: true })}
                  />
                  {errors.address && (
                    <span className="fs-8 text-danger">Address is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Hobbies<span className="fs-8 text-danger"> *</span></label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={hobbies?.map((api) => {
                            return { value: api.value, label: api.name }
                          })}
                          isClearable={true}
                          // isLoading={isLoading} // api loading, isLoading = true
                          id="select-forms-hobbies"
                        />
                      )}
                      defaultValue=""
                      name="hobbies"
                      control={control}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Mobile Number<span className="fs-8 text-danger"> *</span></label>
                  <input type="number" className="form-control"
                    placeholder="Enter mobile number"
                    id="number-forms-mobile_number"
                    {...register("mobile_number", { required: true })}
                  />
                  {errors.mobile_number && (<span className="fs-8 text-danger">Mobile Number is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">College / School Name<span className="fs-8 text-danger"> *</span></label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={collegeName?.map((api) => {
                            return { value: api.value, label: api.name }
                          })}
                          isClearable={true}
                          // isLoading={isLoading} // api loading, isLoading = true
                          id="select-forms-college_school_name"
                        />
                      )}
                      defaultValue=""
                      name="college_school_name"
                      control={control}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Education Name<span className="fs-8 text-danger"> *</span></label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={educationName?.map((api) => {
                            return { value: api.value, label: api.name }
                          })}
                          isClearable={true}
                          // isLoading={isLoading} // api loading, isLoading = true
                          id="select-forms-education_name"
                        />
                      )}
                      defaultValue=""
                      name="education_name"
                      control={control}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Grades<span className="fs-8 text-danger"> *</span></label>
                  <input type="number" className="form-control"
                    placeholder="Enter grades"
                    id="number-forms-grades"
                    {...register("grades", { required: true })}
                  />
                  {errors.grades && (<span className="fs-8 text-danger">Grades is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Marksheet<span className="fs-8 text-danger"> *</span>
                    </label>
                    <Controller
                      name="marksheet"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          className="form-control"
                          id="file-forms-marksheet"
                          type="file"
                          onChange={(e) => field.onChange(e.target.files[0])}
                        />
                      )}
                    />
                    {errors.marksheet && (<span className="fs-8 text-danger">Marksheet is required</span>)}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary"
                      id="submit-button-createproduct">
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStudent;
