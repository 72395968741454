import React from 'react'
import Docker from './Docker'
import Ngnix from './Ngnix'

const DockerizedFile = () => {
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Docker/ Ngnix File</h3>
            <div className="nk-block-des text-soft">
              <p>Download the Docker and Ngnix File by following the simple steps below.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <Docker />
        <Ngnix />
      </div>
    </>
  )
}

export default DockerizedFile