import React from "react";
import LineChart from "./charts/LineChart";
import BarChart from "./charts/BarChart";
import PieChart from "./charts/PieChart";

const ReactApexChartDemo = () => {
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">React Apex Chart</h3>
            <p className="lead">For more details, visit this <a className="text-info" href="https://apexcharts.com/docs/react-charts/" target="_blank">React Apex Chart</a></p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <p>Line Chart</p>
                  <LineChart />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <p>Bar Chart</p>
                  <BarChart />
                </div>
                {/* <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                  <p>Pie Chart</p>
                  <PieChart />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactApexChartDemo;
