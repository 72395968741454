import React, { useState } from "react";
import { createContext } from "react";

export const StateContext = createContext(null);

function StateContextProvider({ children }) {

  const [columnOrder, setColumnOrder] = useState(['select','first_name', 'last_name', 'email', 'date_of_birth','favorite_color'])
  return (
    <div>
      <StateContext.Provider
        value={{
          columnOrder, setColumnOrder
        }}
      >
        {children}
      </StateContext.Provider>
    </div>
  );
}

export default StateContextProvider;
