import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Sample data for the pie chart
const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const PieChartDemo = () => {
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          {/* Legend */}
          <Legend verticalAlign="top" height={36} />

          {/* Pie Definition */}
          <Pie
            data={data}
            cx="50%" // Center x position
            cy="50%" // Center y position
            labelLine={false} // Disable label lines
            label={({ name, value }) => `${name}: ${value}`} // Custom label
            outerRadius={100} // Outer radius of the pie
            fill="#8884d8"
            dataKey="value"
            animationBegin={0}
            animationDuration={500}
            animationEasing="ease-in-out"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>

          {/* Tooltip */}
          <Tooltip formatter={(value) => [`${value}`, "Value"]} />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default PieChartDemo;
