import React, { useState } from 'react'
import Select from 'react-select'

const Filter = (props) => {

  // const [filters,setFilters] = useState([])

  const handleFilterChange = (filterKey, selectedOptions) => {
    props.setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: selectedOptions.map(option => option.value), // Extracting the values from selected options
    }));
  };

  // const handleFilter = () => {
  //   props.setSelectedFilters(filters)
  // }

  return (
    <>
      <div className="modal fade" id="modalForm">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Advance Filter</h5>
              <a href="#" className="close" data-bs-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross"></em>
              </a>
            </div>
            <div className="modal-body">
              <div className="row gx-6 gy-4">
                {
                  props.filters.map((filter, index) => {
                    return <div className="col-6" key={index}>
                      <div className="form-group">
                        <label className="overline-title overline-title-alt">{filter.filter_name}</label>
                        <Select options={filter.filter_option.map((api) => {
                          return { value: api.value, label: api.label }
                        })}
                          isMulti
                          onChange={(selectedOptions) => handleFilterChange(filter.filter_key, selectedOptions)}
                          value={filter.filter_option.filter((option) => props.selectedFilters[filter.filter_key]?.includes(option.value))} />
                      </div>
                    </div>
                  })
                }
                {/* <div className="col-12">
                  <div className="form-group">
                    <button type="button" className="btn btn-secondary" onClick={() => handleFilter()}>Filter</button>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="modal-footer bg-light">
              <span className="sub-text">Modal Footer Text</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Filter