import EditableCell from './EditableCell'
import ColourStatusCell from "./ColourStatusCell"
import RowSelectionCheckbox from "./RowSelectionCheckbox"
import mockData from '../../Data/MOCK_DATA.json'

export const columns = [
  {
    id: "select",
    header: ({ table }) => (
      <RowSelectionCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <RowSelectionCheckbox
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    ),
    enableSorting : false,
    enableColumnFilter : false,
    enableColumnReordering: false,
  },
  {
    accessorKey: "first_name",
    header: "First Name",
    cell: EditableCell,
    filterFn: 'includesString',
    enableColumnFilter : true
  },
  {
    accessorKey: "last_name",
    header: "Last Name",
    cell: EditableCell,
  },
  {
    accessorKey: "age",
    header: "Age",
    cell: EditableCell,
  },
  {
    accessorKey: "email",
    header: "Email Address",
    cell: EditableCell,
  },
  {
    accessorKey: "date_of_birth",
    header: "Date of Birth",
    cell: (props) => <p>{props.getValue()}</p>,
    // sortingFn: 'datetime', // recommended for date columns
  },
  {
    accessorKey: "company_name",
    header: "Company Name",
    cell: EditableCell,
  },
  {
    accessorKey: "favorite_color",
    header: "Favorite Color",
    cell: ColourStatusCell,
    enableGlobalFilter : false
  },
]

// Custome merging the two "keys"
const columnsExample = [
  {
    accessorFn: (row) => `${row.first_name} ${row.last_name}`,
    header: "Name",
    cell: (props) => <p className='fw-bold'>{props.getValue()}</p>,
  },
  // Grouping the headers
  {
    header: "Name",
    columns: [
      {
        accessorKey: "first_name",
        header: "First Name",
        // cell: (props) => <p className='fw-bold'>{props.getValue()}</p>,
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
        // cell: (props) => <p>{props.getValue()}</p>,
      },
    ]
  },
]