import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ReactHelmetDemo = () => {
  const [title, setTitle] = useState("React Helmet Demo");
  const [desc, setDesc] = useState(
    "This page contains the react helmet libraries demo."
  );
  const [keywords, setKeywords] = useState("React, Helmet, SEO");
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* page title */}
          <title>{title}</title>
          {/* page description */}
          <meta name="description" content={desc} />
          {/* content specific keywords */}
          <meta name="keywords" content={keywords} />
          {/* Canonical url */}
          {/* <link rel="canonical" href="https://www.example.com/home" /> */}
          {/* social media title */}
          {/* <meta property="og:title" content="React Helmet Demo" /> */}
          {/* social media description */}
          {/* <meta
          property="og:description"
          content="This page contains the react helmet libraries demo."
        /> */}
          {/* social media image */}
          {/* <meta property="og:image" content="https://example.com/image.jpg" /> */}
          {/* social media url */}
          {/* <meta property="og:url" content="https://example.com/social" /> */}
        </Helmet>
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">React Helmet</h3>
              <p className="lead">For more details, visit this <a className="text-info" href="https://www.npmjs.com/package/react-helmet" target="_blank">React Helmet</a></p>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>
                      React Helmet is a library that helps you deal with search
                      engines and social media crawlers by adding meta tags to
                      your pages/components on React so your site gives more
                      valuable information to the crawlers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                    <label className="form-label">Change title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title..."
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                    <label className="form-label">Change description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Description..."
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 my-2">
                    <label className="form-label">Change keywords</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Keywords..."
                      value={keywords}
                      onChange={(e) => setKeywords(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default ReactHelmetDemo;
