// validationUtils.js
export const isRequired = (value) => value ? true : 'This field is required.';
export const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email) ? true : 'Invalid email format.';
export const isValidPhoneNumber = (phone) => /^\+?\d{10,15}$/.test(phone) ? true : 'Invalid phone number format.';
export const isStrongPassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return regex.test(password) ? true : 'Password must contain at least 8 characters, including uppercase, lowercase, number, and special character.';
};
export const hasValidLength = (value, min, max) => {
    if (value.length < min) return `Minimum length is ${min} characters.`;
    if (value.length > max) return `Maximum length is ${max} characters.`;
    return true;
};
export const isValidDate = (date) => !isNaN(Date.parse(date)) ? true : 'Invalid date format.';
export const isValidURL = (url) => {
    const regex = /^(https?:\/\/)?([a-zA-Z\d-]+)\.([a-zA-Z]{2,})(\/[a-zA-Z\d#]+\/?)*$/;
    return regex.test(url) ? true : 'Invalid URL format.';
};
export const doPasswordsMatch = (password, confirmPassword) => password === confirmPassword ? true : 'Passwords do not match.';
export const isChecked = (checked) => checked ? true : 'You must agree to continue.';
export const isInRange = (num, min, max) => num >= min && num <= max ? true : `Number must be between ${min} and ${max}.`;
export const isValidFileType = (file, allowedTypes) => allowedTypes.includes(file.type) ? true : `Invalid file type. Allowed types: ${allowedTypes.join(', ')}`;
export const isValidFileSize = (file, maxSizeMB) => file.size <= maxSizeMB * 1024 * 1024 ? true : `File size must be less than ${maxSizeMB} MB.`;
