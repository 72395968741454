import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import mock_data from "../Data/MOCK_DATA.json"

export const APIContext = createContext(null);

function APIContextProvider({ children }) {
  const env_url = process.env.REACT_APP_API_URL
  // The below stats should always be in this order

  // User Data stores in "rawUserDetails".
  const [isUserData, setIsUserData] = useState(false);
  const [rawUserDetails, setRawUserDetails] = useState([]);
  const [userEntries, setUserEntries] = useState(5);
  const [userCount, setUserCount] = useState(null);
  console.log(userCount)
  const [userPageCount, setUserPageCount] = useState(0);
  const [isUserLoading, setIsUserLoading] = useState(false);

  // TODO : To display message when the error appears
  const getUserData = async (page, search, filter, sort) => {
    console.log(sort);
    console.log(filter);
    
    setIsUserLoading(true);

    let queryParams = new URLSearchParams();

    // Conditionally add query parameters
    if (search) queryParams.append('event', search);
    if (sort.key) queryParams.append('sortkey', sort.key);
    if (sort.direction) queryParams.append('sortOrder', sort.direction);

    // Add filters dynamically
    if (filter) {
      // Dynamically add selected filters to the query params using filter_key=value format
      Object.keys(filter).forEach((filterKey) => {
        if (filter[filterKey].length > 0) {
          // Join multiple values with commas (or customize this based on your API's expected format)
          queryParams.append(`${filterKey}`, filter[filterKey].join(','));
        }
      });
    }

    let url = `https://rms-api-test.techwalnut.co.in/api/requestrequisition/?entries=${userEntries}&page=${page}`

    const queryString = queryParams.toString();
    if (queryString) {
      url += `&${queryString}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      console.log(res.data)
      setIsUserLoading(false);
      if (response.length !== 0) {
        setIsUserData(false);
        setRawUserDetails(res.data.data);
      } else {
        setIsUserData(true);
        setRawUserDetails(res.data.data);
      }
      const total = res.data.count;
      setUserCount(total);
      setUserPageCount(Math.ceil(total / userEntries));
    } catch (error) {
      console.log(error);
      setIsUserLoading(false);
      setIsUserData(true)
    }
  };

  const handleClick = async (data, search) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await getUserData(currentPage,
      search
    );
  };

  return (
    <div>
      <APIContext.Provider
        value={{
          rawUserDetails,
          getUserData,
          userEntries,
          userPageCount,
          setUserEntries,
          userCount,
          handleClick,
          isUserData,
          isUserLoading,
        }}
      >
        {children}
      </APIContext.Provider>
    </div>
  );
}

export default APIContextProvider;
