import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
  ReferenceLine,
} from "recharts";

const data = [
  { name: "Jan", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Feb", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Mar", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Apr", uv: 2780, pv: 3908, amt: 2000 },
  { name: "May", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Jun", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Jul", uv: 3490, pv: 4300, amt: 2100 },
];

const LineChartDemo = () => {
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
        >
          {/* Gridlines */}
          <CartesianGrid strokeDasharray="3 3" />

          {/* X and Y Axes */}
          <XAxis
            dataKey="name"
            tickFormatter={(tick, index) => (index % 2 === 0 ? tick : "")}
            label={{
              value: "Months",
              position: "insideBottom",
              offset: -3,
            }}
          />
          <YAxis
            label={{ value: "Value", angle: -90, position: "insideLeft" }}
          />

          <ReferenceLine
            y={5500}
            label="Max"
            stroke="red"
            strokeDasharray="3 3"
          />
          <ReferenceLine
            x={"Feb"}
            label="Min"
            stroke="yellow"
            strokeDasharray="3 3"
          />

          {/* Tooltip for data points */}
          <Tooltip />

          {/* Legend */}
          <Legend verticalAlign="top" height={36} />

          {/* Line Definitions */}
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" />

          {/* Brush for zooming */}
          <Brush height={10} y={390} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default LineChartDemo;
